














import Vue from "vue";
import Layout from "@/router/layouts/register.vue";

export default Vue.extend({
  components: {
    Layout,
  },
})
